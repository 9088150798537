








































import { Getter, Action } from 'vuex-class';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { parse, format, setGlobalDateI18n } from 'fecha';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}

@Component({
    components: {
        TableBase: () => import('@/components/table/TableBase.vue'),
        OrderSlideout: () => import('@/components/order/OrderSlideout.vue'),
    },
})
export default class OrderHistory extends Vue {
    @Getter('order/all') orders!: Order[];

    @Action('order/index') indexOrders!: (payload?: { companyId?: number; q?: string; after?: Array<string | number> }) => Promise<Order[]>;

    @Prop() title!: string;
    @Prop() companyId!: number;
    @Prop({ default: false }) full!: boolean;

    i18n: string = 'order.history';

    searchString: string = '';

    $refs!: {
        orderList: HTMLElement;
    };

    localOrders: Order[] = [];

    visibleOrder: Order | null = null;

    tableLayout: { settings: {}; structure: object[] } = {
        settings: {
            locale_path: `${this.i18n}.table`,
            max_height: 'calc(100% - 110px)',
            clickable: true,
        },
        structure: [],
    };

    created() {
        setGlobalDateI18n({
            monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        });

        this.initTableStructure();
    }

    mounted() {
        this.$nextTick(() => {
            const self = this;

            if (this.full) {
                this.initOrderList();
                window.addEventListener('resize', () => self.initOrderList());
                window.addEventListener('orientationchange', () => self.initOrderList());
            }
        });
    }

    initOrderList() {
        window.addEventListener('scroll', this.orderScroll);
    }

    orderScroll(e: Event) {
        const productList: HTMLElement | undefined = this.$refs.orderList;
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        if (productList) {
            if (window.scrollY + vw >= productList.getBoundingClientRect().bottom + window.scrollY) {
                this.loadMore();
            }
        }
    }

    @Watch('orders', { immediate: true })
    productsChanged(val: Order[]) {
        this.localOrders = val;
    }

    @Debounce(500)
    loadMore() {
        this.indexOrders({ companyId: this.companyId, after: this.lastAfter });
    }

    @Debounce(500)
    searchInput() {
        this.indexOrders({ companyId: this.companyId, q: this.searchString });
    }

    get lastAfter(): Array<string | number> {
        const total = this.localOrders.length;
        const last: Order = this.localOrders[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }

    initTableStructure() {
        if (this.full) {
            if (this.companyId) {
                this.tableLayout.structure = [
                    {
                        key: this.$t(`${this.i18n}.table.columns.order_id`),
                        value: (model: Order) => model.id,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.date`),
                        value: (model: Order) => format(parse(model.created_at, 'isoDateTime')!, 'D MMM YYYY'),
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.expected_date`),
                        value: (model: Order) => format(parse(model.shipping_date, 'isoDateTime')!, 'D MMM YYYY'),
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.amount`),
                        value: (model: Order) => `${model.order_lines.length} ${model.order_lines.length > 1 ? 'producten' : 'product'}`,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.placed_by`),
                        value: (model: Order) => `${model.creator.first_name} ${model.creator.last_name}`,
                    },
                    {
                        key: '',
                        value: (model: Order) => `<a class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b" href="/bestellingen/${model.id}">Bestelling bekijken</a>`,
                    },
                ];
            } else {
                this.tableLayout.structure = [
                    {
                        key: this.$t(`${this.i18n}.table.columns.order_id`),
                        value: (model: Order) => model.id,
                        width: 100,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.company_name`),
                        value: (model: Order) => model.company_name,
                        width: 340,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.date`),
                        value: (model: Order) => format(parse(model.created_at, 'isoDateTime')!, 'D MMM YYYY'),
                        width: 120,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.amount`),
                        value: (model: Order) => `${model.order_lines.length} ${model.order_lines.length > 1 ? 'producten' : 'product'}`,
                        width: 160,
                    },
                    {
                        key: this.$t(`${this.i18n}.table.columns.placed_by`),
                        value: (model: Order) => `${model.creator.first_name} ${model.creator.last_name}`,
                        width: 260,
                    },
                    {
                        key: '',
                        value: (model: Order) => `<a class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b" href="/bestellingen/${model.id}">Bestelling bekijken</a>`,
                        width: 140,
                    },
                ];
            }
        } else {
            this.tableLayout.structure = [
                {
                    key: this.$t(`${this.i18n}.table.columns.order_id`),
                    value: (model: Order) => model.id,
                },
                {
                    key: this.$t(`${this.i18n}.table.columns.date`),
                    value: (model: Order) => format(parse(model.created_at, 'isoDateTime')!, 'D MMM YYYY'),
                },
                {
                    key: this.$t(`${this.i18n}.table.columns.expected_date`),
                    value: (model: Order) => format(parse(model.shipping_date, 'isoDateTime')!, 'D MMM YYYY'),
                },
                {
                    key: this.$t(`${this.i18n}.table.columns.placed_by`),
                    value: (model: Order) => `${model.creator.first_name} ${model.creator.last_name}`,
                },
                {
                    key: '',
                    value: (model: Order) => `<span class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b">Bestelling bekijken</span>`,
                },
            ];
        }
    }
}
